<template>
    <div style="overflow-x: hidden;">
        <div class="page_title">
            {{$i18n.t('dashboard.text26')}}
        </div>

        <div class="table_card">
            <div class="header">
                <span class="title">
                    {{$i18n.t('dashboard.text27')}}
                    <el-popover
                        placement="right"
                        title=""
                        width="200"
                        trigger="hover"
                        :content="$i18n.t('dashboard.text28')">

                        <i
                            :class="contentIcon"
                            @mouseover="contentIcon = 'question_coicon icon-yuanyin'"
                            @mouseleave="contentIcon = 'question_alicon iconfont_al icon-yiwen'"
                            slot="reference"></i>

                    </el-popover>
                </span>

                <el-form :inline="true" size="small" class="demo-form-inline">

                    <el-form-item :label="$i18n.t('dashboard.text29')">
                        <el-cascader
                            clearable
                            @change="getMsg(1)"
                            :show-all-levels="false"
                            :placeholder="$i18n.t('dashboard.text30')"
                            v-model="productForm.productType"
                            :options="productTypeList"
                            :props="props">
                        </el-cascader>
                    </el-form-item>

                    <el-form-item>
                        <el-date-picker
                            style="width: 145px"
                            @change="getMsg(1)"
                            :clearable="false"
                            v-model="productForm.day"
                            type="date"
                            value-format="yyyy-MM-dd"
                            :picker-options="pickerOptions"
                            :placeholder="$i18n.t('dashboard.text31')">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item>
                        <el-input :placeholder="$i18n.t('dashboard.text32')" v-model="productForm.deviceId" size="small" style="width: 330px;">
                            <el-button slot="append" type="text" icon="el-icon-search" @click="getMsg(1)"></el-button>
                        </el-input>
                    </el-form-item>

                </el-form>
            </div>



            <el-table
                ref="factoryTestTable"
                class="factoryTestTable"
                :data="deviceStatisticsMsg.list"
                :height="deviceStatisticsMsg.list.length === 0 ? tableHeight : ''"
                :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"
                :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"
                v-loading="loading"
                @sort-change="sortChange"
                style="width: 100%;">

                <el-table-column
                    min-width="100"
                    :label="$i18n.t('dashboard.text29')">
                    <template slot-scope="scope">
                        {{language === 'zh' ? JSON.parse(scope.row.categoryName).zh : JSON.parse(scope.row.categoryName).en}}
                    </template>
                </el-table-column>

                <el-table-column
                    prop="deviceId"
                    :label="$i18n.t('dashboard.text33')">
                </el-table-column>

                <el-table-column
                    sortable="custom"
                    prop="online_times"
                    :label="$i18n.t('dashboard.text34')">
                    <template slot-scope="scope">
                        {{scope.row.onlineTimes ? scope.row.onlineTimes : 0}}
                    </template>
                </el-table-column>

                <el-table-column>
                    <template slot="header" slot-scope="scope">
                        <div style="display: flex;flex-direction: row;align-items: center;">

                            <div style="margin-right: 4px;">{{$i18n.t('dashboard.text35')}}</div>

                            <el-popover
                                placement="right"
                                title=""
                                width="200"
                                trigger="hover"
                                :content="$i18n.t('dashboard.text36')">

                                <i
                                    :class="tableIcon"
                                    @mouseover="tableIcon = 'question_coicon icon-yuanyin'"
                                    @mouseleave="tableIcon = 'question_alicon iconfont_al icon-yiwen'"
                                    slot="reference"></i>

                            </el-popover>
                        </div>
                    </template>
                    <template slot-scope="scope">
                        <span :style="{color: scope.row.onlineTimes >= 50 ? 'rgb(255, 40, 37)' : '#16C60C'}">
                            {{scope.row.onlineTimes >= 50 ? $i18n.t('dashboard.text37') : $i18n.t('dashboard.text38')}}
                        </span>
                    </template>
                </el-table-column>

                <el-table-column
                    :label="$i18n.t('dashboard.text42')">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            :loading="scope.row.loading"
                            @click="showMsgDialog(scope.row)"
                            size="small">
                            {{scope.row.deviceOwnerTelephone ? `${scope.row.deviceOwnerAreaNumber}-${scope.row.deviceOwnerTelephone}` : scope.row.deviceOwnerEmail}}
                        </el-button>
                    </template>
                </el-table-column>

                <el-table-column
                    :label="$i18n.t('member.text50')"
                    prop="remark"
                >

                </el-table-column>

                <el-table-column
                    :label="$i18n.t('dashboard.text16')">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            @click="toLog(scope.row)"
                            size="small">
                            {{$i18n.t('dashboard.text17')}}
                        </el-button>
                        <el-button
                            type="text"
                            @click="updateRemark(scope.row)"
                            size="small">
                            {{$i18n.t('dashboard.text44')}}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>


            <el-pagination
                background
                @current-change="handleCurrentChange"
                :page-size="10"
                :current-page="page"
                layout=" prev, pager, next"
                style="margin-top: 10px;text-align: right;"
                :total="parseInt(deviceStatisticsMsg.totalRecords)">
            </el-pagination>
        </div>


        <el-dialog
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :title="$i18n.t('dashboard.text43')"
            :visible.sync="showUserMsgDialogVisible"
            width="300px">
            <p style="font-weight: bold;">{{$i18n.t('userInfo.text4')}}：</p>
            <p>
                <span id="nickName">{{userMsg.nickName ? userMsg.nickName : '--'}}</span>
                <i class="el-icon-copy-document copy" data-clipboard-target="#nickName" v-show="userMsg.nickName"></i>
            </p>

            <p style="font-weight: bold;">{{$i18n.t('userInfo.text16')}}：</p>
            <p>
                <span id="telephone">{{userMsg.telephone ? `${userMsg.areaNumber}-${userMsg.telephone}` : '--'}}</span>
                <i class="el-icon-copy-document copy" data-clipboard-target="#telephone" v-show="userMsg.telephone"></i>
            </p>

            <p style="font-weight: bold;">{{$i18n.t('userInfo.text20')}}：</p>
            <p>
                <span id="email">{{userMsg.email ? userMsg.email : '--'}}</span>
                <i class="el-icon-copy-document copy" data-clipboard-target="#email" v-show="userMsg.email"></i>
            </p>

            <div class="demo-drawer__footer" style="text-align: right;margin-top: 30px">
                <el-button type="primary" size="small" @click="showUserMsgDialogVisible = false">{{$i18n.t('empower.text86')}}</el-button>
            </div>
        </el-dialog>


        <el-dialog
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :title="$i18n.t('dashboard.text44')"
            :visible.sync="showModifyRemarkDialogVisible"
            width="300px"
            @close="closeDialog"
            @open="openDialog">
            <el-form :model="form" size="small" style="margin-top: 20px;" :rules="rules" ref="ruleForm">
                <el-form-item prop="remark">
                    <el-input
                        type="textarea"
                        :rows="3"
                        :maxlength="25"
                        show-word-limit
                        v-model.trim="form.remark"
                        :placeholder="$i18n.t('product.text61')">
                    </el-input>
                </el-form-item>
            </el-form>

            <div class="demo-drawer__footer" style="text-align: right;margin-top: 30px">
                <el-button type="primary" size="small" @click="mRemark" :loading="modifyRemarkLoading">{{$i18n.t('dashboard.text21')}}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {mapActions} from "vuex";
import Clipboard from "clipboard";

export default {

    name: "deviceStatistics",

    data()  {
        return {
            contentIcon: "question_alicon iconfont_al icon-yiwen",

            tableIcon: "question_alicon iconfont_al icon-yiwen",

            showUserMsgDialogVisible: false,

            showModifyRemarkDialogVisible: false,

            clipboard: null,

            page: 1,

            //查询表单
            productForm: {
                productType: "",
                deviceId: "",
                day: ""
            },

            //品类数据
            productTypeList: [],


            //级联选择器配置选项
            props: {
                //开启懒加载
                lazy: true,
                //指定选项的值为选项对象的某个属性值
                value: "code",
                //指定选项标签为选项对象的某个属性值
                label: "name",
                //指定选项的子选项为选项对象的某个属性值
                children: "children",
                //加载动态数据的方法
                //node为当前点击的节点，resolve为数据加载完成的回调(必须调用)
                lazyLoad: (node, resolve) => {
                    console.log(node);
                    if (!node.value) {
                        //获取一级品类
                        this.getProtocolTypeList();
                        return
                    }
                    this.getLevelProtocolTypeList(node.value, resolve)
                }
            },

            deviceStatisticsMsg: {
                list: [],
                totalRecords: 10
            },

            tableHeight: 50,

            loading: false,

            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            language: localStorage.getItem('language'),

            userMsg: {
                "areaNumber": "",
                "email": "",
                "nickName": "",
                "telephone": "",
                "userId": 0
            },

            form: {
                deviceId: "",
                remark: ""
            },

            rules: {
                remark: [
                    {required: true, message: this.$i18n.t('product.text61'), trigger: 'blur'},
                ]
            },

            modifyRemarkLoading: false,

            orderBy: "",
            isAsc: ""


        }
    },

    mounted() {
        setTimeout(() => {
            this.tableHeight = (window.innerHeight - 280);
        }, 100);
        window.addEventListener('resize', this.setHeight);
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        this.productForm.day = `${year}-${month < 10 ? ('0' + month) : month}-${day < 10 ? ('0' + day) : day}`;
        this.getMsg();
        this.clipboard = new Clipboard('.copy');
        this.clipboard.on('success', (e) => {
            console.log("复制成功", e);
            this.$dialog.showMessage(this.$i18n.t('empower.text34'), this.$config.TOAST_SUCCESS);
        });
    },

    destroyed() {
        this.clipboard.destroy();
    },

    methods: {

        ...mapActions('category', ['queryFirstLevelCategory', 'queryChildsCategory']),
        ...mapActions('onlineStatistics', ['queryOnlineStatisticsByDay', 'modifyRemark', 'copyOwnerInfo']),

        /**
         * 设备表格高度
         */
        setHeight() {
            this.tableHeight = (window.innerHeight - 280);
        },

        toLog(row) {
            this.$router.push({
                path: "/deviceLog",
                query: {
                    deviceId: row.deviceId
                }
            })
        },

        updateRemark(row) {
            this.form.deviceId = row.deviceId;
            this.form.remark = row.remark;
            this.showModifyRemarkDialogVisible = true;
        },

        mRemark() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.modifyRemarkLoading = true;
                    this.modifyRemark({
                        deviceId: this.form.deviceId,
                        remark: this.form.remark
                    }).then(res => {
                        this.$dialog.showMessage(this.$i18n.t('dashboard.text22'), this.$config.TOAST_SUCCESS);
                        this.getMsg();
                        this.showModifyRemarkDialogVisible = false;
                    }, err => {
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                    }).finally(() => this.modifyRemarkLoading = false)
                }
            })
        },

        showMsgDialog(row) {
            row.loading = true;
            this.copyOwnerInfo({deviceId: row.deviceId}).then(res => {
                this.showUserMsgDialogVisible = true;
                this.userMsg = res.result;
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => row.loading = false)
        },

        closeDialog() {
            this.$refs.ruleForm.resetFields();
        },

        openDialog() {},

        getMsg(page) {
            console.log(this.productForm)
            this.loading = true;
            if (page) this.page = page;
            let data = {
                currPage: this.page,
                pageSize: 10,
                deviceId: this.productForm.deviceId,
                theDate: this.productForm.day,
                categoryCode: this.productForm.productType[2]
            }
            if (this.orderBy && this.isAsc) data.orderBy = this.orderBy;
            if (this.isAsc) data.isAsc = this.isAsc === "ascending";
            this.queryOnlineStatisticsByDay(data).then(res => {
                res.result.list.forEach(item => {
                    item.loading = false;
                })
                this.deviceStatisticsMsg.list = res.result.list;
                this.deviceStatisticsMsg.totalRecords = res.result.totalRecords;
                console.log(this.deviceStatisticsMsg.totalRecords)
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => this.loading = false)
        },

        /**
         * 获取产品类型列表
         */
        getProtocolTypeList() {
            this.queryFirstLevelCategory().then(res => {
                if (res.result.length == 0) return
                res.result.forEach(item => {
                    item.name = this.language === 'zh' ? JSON.parse(item.name).zh : JSON.parse(item.name).en;
                    item.leaf = false;
                })
                this.productTypeList = res.result
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            });
        },


        /**
         * 获取下级产品类型列表
         * @param code
         * @param resolve
         */
        getLevelProtocolTypeList(code, resolve) {
            this.queryChildsCategory({
                code
            }).then(res => {
                res.result.forEach(item => {
                    item.name = this.language === 'zh' ? JSON.parse(item.name).zh : JSON.parse(item.name).en;
                    if (item.level == 2) {
                        item.children = [];
                        item.leaf = false;
                    }
                    if (item.level == 3) {
                        item.leaf = true;
                    }
                })
                console.log(res.result)
                resolve(res.result)
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },



        sortChange({ prop, order }) {
            console.log(prop, order);
            this.orderBy = prop;
            this.isAsc = order
            this.getMsg(1);
        },


        handleCurrentChange(page) {
            this.getMsg(page);
        }
    }

}
</script>

<style scoped>


.table_card {
    background: #FFFFFF;
    border-radius: 8px;
    position: relative;
    margin: 64px 24px 20px 24px;
    box-shadow: 0 0 4px 0 #BCBCBC;
    padding: 10px 24px;
    animation: fadeInRight; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s; /* don't forget to set a duration! */
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 14px 0 20px 0;
}

.question_alicon {
    color: #3296FA;
    font-size: 16px;
    cursor: pointer;
}


.question_coicon {
    color: #3296FA;
    font-size: 16px;
    cursor: pointer;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.el-form-item {
    margin-bottom: 0!important;
}
</style>
