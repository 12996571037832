<template>
    <el-card
        class="authorization_table"
        :body-style="{
            textAlign: 'left',
            padding: '15px'
        }"
        shadow="never">
        <div slot="header" class="clearfix">
            <span>{{$i18n.t('empower.text100')}}</span>
        </div>
        <el-empty
            v-if="empowerMsg.state !== 1"
            :description="empowerMsg.state === 0 ? $i18n.t('empower.text101') : $i18n.t('empower.text102')"
            :image="empowerMsg.state === 0 ? require('../../../assets/icon_review0.png') : require('../../../assets/icon_review2.png')"></el-empty>

        <div class="header_btn"
             v-if="empowerMsg.state === 1">
            <el-form :inline="true" size="small">
                <el-form-item>
                    <el-input
                        v-model="deviceFrom.keyword"
                        clearable
                        suffix-icon="el-icon-search"
                        :placeholder="empowerMsg.activeType === 2 ? $i18n.t('empower.text103') : $i18n.t('empower.text104')"
                        @clear="getMsg(1)"
                        @keydown.enter.native="getMsg(1)"/>
                </el-form-item>

                <el-form-item v-if="empowerMsg.activeType !== 3">
                    <el-select
                        :popper-append-to-body="false"
                        v-model="deviceFrom.deviceState"
                        clearable
                        :placeholder="$i18n.t('empower.text105')"
                        @change="getMsg(1)">
                        <el-option
                            v-for="item in $message.deviceStateList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item style="float: right;">
                    <el-button class="btn" size="small" type="primary" @click="exportMember" plain v-if="empowerMsg.activeType === 2">
                        {{$i18n.t('empower.text106')}}
                    </el-button>
<!--                    <el-button class="btn" size="small" type="primary" @click="addImport" plain>-->
<!--                        新增导入-->
<!--                    </el-button>-->
                </el-form-item>
            </el-form>
        </div>

        <el-table
            v-if="empowerMsg.state === 1"
            ref="deviceSignTable"
            class="deviceSign_table"
            :data="deviceSignMsg.list"
            :header-cell-style="{background:'#eef1f6',color:'#606266'}"
            v-loading="loading"
            style="width: 100%;">
            <el-table-column
                prop="license"
                label="License"
                v-if="empowerMsg.activeType === 2"
            >
            </el-table-column>
            <el-table-column
                :label="$i18n.t('empower.text107')"
            >
                <template slot-scope="scope">
                    {{ scope.row.uniqueKey ? scope.row.uniqueKey : "—" }}
                </template>
            </el-table-column>
            <el-table-column
                :label="$i18n.t('empower.text108')"
            >
                <template slot-scope="scope">
                    {{ $config.getMsgItemUtil($message.deviceStateList, scope.row.status, 'id', 'name') }}
                </template>
            </el-table-column>
            <el-table-column
                :label="$i18n.t('empower.text20')">
                <template slot-scope="scope">
                    {{
                        $config.getMsgItemUtil($message.activeTypeList, scope.row.activeType, 'id', 'name')
                    }}
                </template>
            </el-table-column>
            <el-table-column
                :label="$i18n.t('empower.text109')"
            >
                <template slot-scope="scope">
                    {{ scope.row.activationTime ? scope.row.activationTime : "—" }}
                </template>
            </el-table-column>

        </el-table>

        <el-pagination
            v-if="empowerMsg.state === 1"
            background
            @current-change="handleCurrentChange"
            :page-size="10"
            :current-page="page"
            layout=" prev, pager, next"
            style="float: right;margin-top: 10px;margin-bottom: 10px;"
            :total="parseInt(deviceSignMsg.totalRecords)">
        </el-pagination>

        <!-- 新增激活设备弹窗 -->
        <add-activation-dialog @getMsg="getMsg"></add-activation-dialog>

        <!-- 导出license -->
        <export-license-dialog></export-license-dialog>

    </el-card>
</template>

<script>
import {mapActions, mapState} from "vuex";
import AddActivationDialog from "@/views/empower/components/dialog/addActivationDialog";
import ExportLicenseDialog from "@/views/empower/components/dialog/exportLicenseDialog";

export default {

    name: "deviceSignTable",

    components: {AddActivationDialog, ExportLicenseDialog},

    computed: {
        ...mapState('empower', ['itemMsg'])
    },

    watch: {
        itemMsg: {
            handler(newVal) {
                this.empowerMsg = newVal;
            },
            deep: true
        }
    },

    data() {

        return {

            loading: false,

            deviceFrom: {
                keyword: "",
                deviceState: ""
            },

            empowerMsg: {},

            page: 1,


            deviceSignMsg: {}

        }

    },

    mounted() {
        this.getMsg();
    },

    methods: {

        ...mapActions('empower', ['queryUniqueKey', 'exportuniquekey', 'queryMember']),

        addImport() {
            this.$store.commit("empower/SET_ADD_ACTIVATION_DIALOG_VISIBLE", true);
        },

        /**
         * 获取已激活设备信息
         */
        getMsg(page) {
            if (page) this.page = page;
            this.loading = true
            this.queryMember({
                authorizationCode: this.$route.query.code,
                currPage: this.page,
                pageSize: 10,
                status: this.deviceFrom.deviceState,
                key: this.deviceFrom.keyword
            }).then(res => {
                this.deviceSignMsg = res.result;
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.loading = false;
            })
        },

        /**
         * 导出设备唯一标识
         */
        exportMember() {
            this.$store.commit('empower/SET_EXPORT_LICENSE_DIALOG_VISIBLE', true);
            // this.exportuniquekey({
            //     authorizationCode: this.$route.query.code
            // }).then(res => {
            //     let fileName = `${this.$route.query.code}.xlsx`;
            //     let objectUrl = URL.createObjectURL(new Blob([res.data]))
            //     const link = document.createElement('a')
            //     link.download = decodeURI(fileName)
            //     link.href = objectUrl
            //     link.click();
            // }, err => {
            //     this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            // })
        },

        handleCurrentChange(page) {
            this.page = page;
            this.getMsg()
        }

    }

}
</script>

<style scoped>
.authorization_table {
    width: 100%;
}

.el-card {
    margin: 24px 0 !important;
    animation: fadeInUp; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s; /* don't forget to set a duration! */
}

/deep/ .el-card__header {
    padding: 10px;
    font-size: 14px;
    text-align: left;
    font-weight: bold;
}

.el-select {
    width: 150px;
}
</style>
