<template>
    <div>
        <div class="page_title">
            <i class="el-icon-arrow-left" style="cursor: pointer;" @click="back()"></i>
            {{$i18n.t("addProduct.text1")}}
        </div>
        <el-card
            :body-style="{
                textAlign: 'left',
                padding: '15px',
                height: '100%'
            }"
            shadow="never">
            <div class="block">
                <el-timeline>
                    <el-timeline-item
                        v-for="(item, index) in activities"
                        :timestamp="item"
                        :key="index"
                        color="#1890FF"
                        size="large"
                        :icon="'iconfont_al step_icon icon-' + (index+1)"
                        placement="top">
                        <div v-if="index == 0" class="step0">
                            <el-row>
                                <el-col :span="24">
                                    <el-form :inline="true" :model="searchForm" size="small"
                                             class="form-inline" @submit.native.prevent>
                                        <el-form-item>
                                            <el-input v-model.trim="searchForm.categoryName" clearable
                                                      :placeholder="$i18n.t('addProduct.text2')"
                                                      @clear="search()"
                                                      @keyup.enter.native="search()"
                                                      @blur="search()"></el-input>
                                        </el-form-item>
                                    </el-form>
                                </el-col>
                            </el-row>
                            <el-tabs type="border-card" v-model="activeName" class="main"
                                     @tab-click="actionTab" v-if="productTypeList.length > 0">
                                <el-tab-pane :label="item.name" :name="item.id"

                                             v-for="(item,index) in productTypeList" :key="index">
                                    <select-product-drawer
                                        @closeLoad="closeLoad"
                                        :searchSelectItemList="searchSelectItemList"
                                        :item="{
                                            id: item.id,
                                            name: item.name
                                        }"
                                        :ref="'selectProduct'+item.id"
                                        class="content_menu">
                                    </select-product-drawer>
                                </el-tab-pane>
                            </el-tabs>
                            <el-empty :description="$i18n.t('addProduct.text3')" style="height: 350px;" :image-size="100"
                                      v-else></el-empty>
                        </div>
                        <div v-if="index == 1" class="step1">
                            <el-row :gutter="20">
                                <el-col :span="8" v-for="(item, index) in programmeList" :key="index">
                                    <div
                                        class="programme_item"
                                        :style="{
                                            opacity: item.isDisable ? '0.5' : '1',
                                            cursor: item.isDisable ? 'not-allowed' : 'pointer'
                                        }"
                                        @click="!item.isDisable && cancelSelect(item.id)">
                                        <div class="programme_item_title">{{ item.title }}</div>
                                        <div class="programme_item_subtitle">{{ item.subtitle }}</div>
                                        <el-radio
                                            :disabled="item.isDisable"
                                            v-model="programmeId"
                                            :label="item.id"
                                            @click.native="cancelSelect(item.id, $event)"
                                            class="programme_radio">{{ " " }}
                                        </el-radio>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                        <div v-else-if="index == 2" class="step2">

                            <el-form :model="form" size="small" :rules="rules" ref="ruleForm"
                                     :label-width="formLabelWidth">
                                <el-form-item :label="$i18n.t('addProduct.text4')" prop="productName">
                                    <el-input
                                        type="text"
                                        v-model.trim="form.productName"
                                        :placeholder="$i18n.t('addProduct.text5')">
                                    </el-input>
                                </el-form-item>
                                <el-form-item :label="$i18n.t('addProduct.text6')" prop="productModel">
                                    <el-input
                                        type="text"
                                        v-model.trim="form.productModel"
                                        :placeholder="$i18n.t('addProduct.text7')">
                                    </el-input>
                                </el-form-item>
                                <el-form-item :label="$i18n.t('addProduct.text8')" prop="communication">
                                    <el-select @change="selectProtocolType" :popper-append-to-body="false" v-model="form.communication" :placeholder="$i18n.t('addProduct.text9')">
                                        <el-option
                                            v-for="item in $message.protocolTypeList"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="$i18n.t('addProduct.text10')" v-if="form.communication && networkList.length > 0" prop="network">
                                    <el-checkbox-group v-model="form.network">
                                        <!--当通讯方式为wifi时配网方式默认为wifi配网-->
                                        <el-checkbox :label="item.code" v-for="(item, index) in networkList" :key="index">
                                            {{ language === 'zh' ? JSON.parse(item.name).zh : JSON.parse(item.name).en }}
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </el-form-item>
                                <el-form-item :label="$i18n.t('addProduct.text11')">
                                    <div class="category_item" v-if="form.file">
                                        <div class="image">
                                            <el-button type="text" icon="el-icon-error" size="medium"
                                                       @click="form.file = ''" class="close_img"></el-button>
                                            <el-image
                                                style="width: 94px;height: 94px;"
                                                :src="form.file"
                                                :preview-src-list="[form.file]"></el-image>
                                        </div>
                                    </div>
                                    <el-upload
                                        v-else
                                        class="avatar-uploader"
                                        :action="$message.uploadImgUrl"
                                        :data="{
                                           bucket: 'product'
                                        }"
                                        :headers="{
                                            accessToken: $token.getToken().accessToken,
                                            refreshToken: $token.getToken().refreshToken,
                                            language: $message.language
                                        }"
                                        :show-file-list="false"
                                        :on-success="handleAvatarSuccess"
                                        :on-error="onErr"
                                        accept=".png"
                                        :before-upload="beforeAvatarUpload">
                                        <img v-if="form.file" :src="form.file" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload>
                                    <span class="tips">{{$i18n.t('addProduct.text12')}}</span>
                                </el-form-item>

                                <el-form-item :label="$i18n.t('addProduct.text13')">
                                    <el-input
                                        type="textarea"
                                        :rows="4"
                                        maxlength="200"
                                        :placeholder="$i18n.t('addProduct.text14')"
                                        show-word-limit
                                        v-model="form.remark">
                                    </el-input>
                                </el-form-item>
                            </el-form>

                        </div>
                        <div v-else-if="index == 3" class="step3">
                            <el-button :loading="submitLoading" @click="submit" type="primary">{{$i18n.t('addProduct.text15')}}</el-button>
                        </div>
                    </el-timeline-item>
                </el-timeline>
            </div>

        </el-card>
    </div>
</template>

<script>

import selectProductDrawer from '@/views/product/views/basic/components/selectProductDrawer';
import adminHeader from '@/views/main/components/adminHeader';
import {mapActions, mapState} from "vuex";

export default {
    components: {
        adminHeader,
        selectProductDrawer
    },
    computed: {
        ...mapState('product', ['selectCategoryMsg'])
    },
    watch: {
        selectCategoryMsg: {
            handler(newVal) {
                this.selectCategoryItem = newVal;
            },
            deep: true
        }
    },
    name: "addProduct",

    data() {
        return {
            formLabelWidth: "200px",
            activeName: '',
            activities: [
                this.$i18n.t('addProduct.text16'),
                this.$i18n.t('addProduct.text17'),
                this.$i18n.t('addProduct.text18'),
                this.$i18n.t('addProduct.text19')
            ],
            selectCategoryItem: {},
            loading: false,
            btnLoading: false,
            submitLoading: false,
            productTypeList: [],
            selectMsg: {
                id: "",
                name: "",
                isSearch: false
            },
            programmeId: "",
            programmeList: [
                {
                    id: 1,
                    title: this.$i18n.t('addProduct.text20'),
                    subtitle: this.$i18n.t('addProduct.text21'),
                    isDisable: false
                },
                {
                    id: 2,
                    title: this.$i18n.t('addProduct.text22'),
                    subtitle: this.$i18n.t('addProduct.text23'),
                    isDisable: false
                },
                {
                    id: 3,
                    title: this.$i18n.t('addProduct.text24'),
                    subtitle: this.$i18n.t('addProduct.text25'),
                    isDisable: true
                }
            ],
            isSearch: false,
            searchSelectItemList: [],
            searchForm: {
                categoryName: ""
            },
            form: {
                productName: "",
                productModel: "",
                communication: "",
                file: "",
                remark: "",
                network: []
            },
            rules: {
                productName: [
                    {required: true, message: this.$i18n.t('addProduct.text26')},
                    {pattern: /^(?=[\S\s]{1,20}$)[\S\s]*/, message: this.$i18n.t('addProduct.text27')},
                ],
                productType: [{required: true, message: this.$i18n.t('addProduct.text28')}],
                communication: [{required: true, message: this.$i18n.t('addProduct.text29')}],
                productModel: [
                    {pattern: /^(?=[\S\s]{1,20}$)[\S\s]*/, message: this.$i18n.t('addProduct.text27')},
                    {pattern: /^[a-z_A-Z_0-9]*$/, message: this.$i18n.t('addProduct.text30')},
                ],
                network: [{required: true, message: this.$i18n.t('addProduct.text31')}]
            },
            networkList: [],
            isRepeat: false,
            language: ""
        }
    },

    mounted() {
        this.language = window.localStorage.getItem('language');
        this.formLabelWidth = this.language === 'zh' ? '100px' : '200px'
        this.handleOpen(false);
        this.cleanSelect();
    },

    methods: {

        ...mapActions('category', ['queryFirstLevelCategory', 'searchCategoryTree']),
        ...mapActions('product', ['addProduct', 'getNetworkModeListByProtocolType']),

        selectProtocolType(val) {
            this.getNetworkModeListByProtocolType({
                protocolType: val
            }).then(res => {
                this.networkList = res.result ? res.result : [];
                // if (val === 1) {
                //     //当通讯方式为wifi时配网方式默认为wifi配网
                //     this.form.network = val === 1 ? ["1001"] : [];
                // }
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        cancelSelect(code, e) {
            if (e && e.target.tagName === 'INPUT') return;
            this.programmeId === code ? this.programmeId = "" : this.programmeId = code;
        },

        /**
         * 清除已选品类
         */
        cleanSelect() {
            this.$store.commit("product/SET_SELECT_CATEGORY_MSG", {});
        },

        /**
         * 查询品类
         * @param isSearch
         */
        handleOpen(isSearch) {
            this.loading = true;
            this.queryFirstLevelCategory().then(res => {
                this.loading = false;
                if (!res.result) return;
                let list = [];
                for (let i = 0; i < res.result.length; i++) {
                    let {name, code, children} = res.result[i];
                    let zhName = this.language === 'zh' ? JSON.parse(name).zh : JSON.parse(name).en
                    list.push({name: zhName, id: code, isSearch, children: children ? children : []});
                }
                this.isSearch = isSearch;
                this.productTypeList = list;
                this.$nextTick(() => {
                    if (!isSearch) return;
                    this.productTypeList.forEach(item => {
                        this.$refs['selectProduct' + item.id][0].handleOpen();
                    })
                })
                this.activeName = list[0].id;
                this.selectItem(list[0])
            }, err => {
                this.loading = false;
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        /**
         * 切换品类tab时更新选中品类
         * @param msg
         * @param event
         */
        actionTab(msg, event) {
            this.$refs['selectProduct' + msg.name][0].init();
        },

        getItemList(code) {
            let list = [];
            this.productTypeList.forEach(item => {
                if (item.id == code) {
                    list = item.children && item.children.length > 0 ? item.children : [];
                }
            })
            return list;
        },

        /**
         * 搜索品类
         */
        search() {
            if (!this.searchForm.categoryName) {
                this.searchSelectItemList = [];
                this.handleOpen(true);
                return;
            }
            this.isSearch = true;
            this.loading = true;
            this.searchCategoryTree({
                keyword: this.searchForm.categoryName
            }).then(res => {
                if (!res.result) return;
                let list = [];
                for (let i = 0; i < res.result.length; i++) {
                    let {name, code, children} = res.result[i];
                    let zhName = this.language === 'zh' ? JSON.parse(name).zh : JSON.parse(name).en
                    list.push({name: zhName, id: code, isSearch: true, children: children ? children : []});
                }
                this.productTypeList = list;
                this.selectItem(list.length > 0 ? this.productTypeList[0] : {})
                this.activeName = list.length > 0 ? list[0].id : 0;
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.loading = false;
                this.btnLoading = false;
            })
        },

        /**
         * 设置选中
         * @param item
         */
        selectItem(item) {
            this.selectMsg = item;
            this.searchSelectItemList = this.isSearch ? this.getItemList(item.id) : [];
            this.loading = !this.isSearch;
        },

        /**
         * 关闭品类加载
         */
        closeLoad() {
            this.loading = false;
        },

        handleAvatarSuccess(res, file) {
            if (res.success) {
                this.form.file = res.result.fileList[0].url;
            } else {
                this.$dialog.showMessage(res.resultMsg, this.$config.TOAST_ERROR);
            }
        },

        beforeAvatarUpload(file) {
            const isPNG = file.type === 'image/png';
            const isLt2M = file.size / 1024 < 100;
            if (!isPNG) {
                this.$dialog.showMessage(this.$i18n.t('addProduct.text32'), this.$config.TOAST_WARNING);
                return false;
            }
            if (!isLt2M) {
                this.$dialog.showMessage(this.$i18n.t('addProduct.text33'), this.$config.TOAST_WARNING);
                return false;
            }
            const isSize = new Promise(function (resolve, reject) {
                let width = 168;
                let height = 168;
                let _URL = window.URL || window.webkitURL;
                let image = new Image();
                image.onload = function () {
                    let valid = image.width == width && image.height == height;
                    valid ? resolve() : reject();
                };
                image.src = _URL.createObjectURL(file);
            }).then(
                () => {
                    return file;
                },
                () => {
                    this.$dialog.showMessage(this.$i18n.t('addProduct.text34'), this.$config.TOAST_WARNING);
                    return Promise.reject();
                }
            );

            return isPNG && isLt2M && isSize;
        },

        onErr(err, file, fileList) {
            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_WARNING);
        },

        /**
         * 返回上一页
         */
        back() {
            this.$router.back();
        },

        /**
         * 提交新增产品表单
         */
        submit() {
            this.$refs.ruleForm[0].validate((valid) => {
                if (!this.selectCategoryItem.code) {
                    this.$dialog.showMessage(this.$i18n.t('addProduct.text35'), this.$config.TOAST_WARNING);
                    return;
                }
                if (!this.programmeId) {
                    this.$dialog.showMessage(this.$i18n.t('addProduct.text36'), this.$config.TOAST_WARNING);
                    return;
                }
                if (valid) {
                    this.submitLoading = true;
                    let data = {
                        categoryCode: this.selectCategoryMsg.code,
                        icon: this.form.file,
                        schemeType: this.programmeId,
                        model: this.form.productModel,
                        name: this.form.productName,
                        protocolType: this.form.communication,
                        remark: this.form.remark,
                    }
                    if (this.form.network.length > 0) {
                        let list = [];
                        this.form.network.forEach((item, index) => {
                            list.push({
                                networkModeCode: item,
                                isDefault: index === 0 ? "Y" : "N"
                            })
                        })
                        data.networkModeData = JSON.stringify(list);
                    }
                    this.addProduct(data).then(res => {
                        this.submitLoading = false;
                        this.$dialog.showMessage(this.$i18n.t('addProduct.text37'), this.$config.TOAST_SUCCESS);
                        // this.$store.commit("product/SET_SELECT_CATEGORY_MSG", item);
                        this.$router.push({path: "/product", query: {id: res.result}});
                    }, err => {
                        this.submitLoading = false;
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                    })
                }
            })
        }

    }

}
</script>


<style scoped>
/deep/ .el-card__header {
    padding: 10px !important;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
}

.el-breadcrumb {
    line-height: normal;
}

/deep/ .el-breadcrumb__inner, /deep/ .el-breadcrumb__separator {
    font-weight: bold !important;
    font-size: 16px !important;
    color: black !important;
}

/deep/ .el-card__body {
    height: 100%;
    overflow-y: auto;
    margin-bottom: 10px;
}

.main-container {
    background-color: #E8ECF0;
    height: 100%;
    margin-top: 48px;
    padding: 18px;
}

.el-card {
    border: none;
    border-radius: 8px;
    margin: 60px 20px 20px 20px;
    padding: 20px 0;
    animation: fadeInRight; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s; /* don't forget to set a duration! */
}

/deep/ .el-timeline-item__node--large {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
}


.main {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0 0 4px 0 #BCBCBC;
    border-radius: 8px;
    border: none;
}

/deep/ .main .el-tabs__header {
    border-radius: 8px 8px 0 0;
}

/deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    background-color: #0000;
}

/deep/ .el-tabs__item {
    height: 24px;
    line-height: 24px;
    margin: 12px 0;
}

/deep/ .el-tabs__item :first-child {
    border-left: none;
}

/deep/ .el-tabs--border-card > .el-tabs__header {
    border-bottom: 1px dashed #E1E1E1;
    background-color: #ffffff;
}

/deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item {
    margin-top: 12px;
    border-right: 1px solid #E1E1E1;
}

.tips {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.25);
}

.image {
    position: relative;
    width: 94px;
    height: 94px;
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 10px;
}

.close_img {
    color: black;
    position: absolute;
    right: 8px;
    margin-top: -12px;
    z-index: 99;
}

.select_card {
    width: 300px;
    height: 40px;
    border: 1px solid #E4E7ED;
}

.select_img {
    width: 30px;
    height: 30px;
    margin: 5px;
    float: left;
}

.select_title {
    width: 150px;
    float: left;
    line-height: 40px;
    margin-left: 10px;
}

.select_close {
    float: right;
    margin-right: 10px;
}

/deep/ .el-tabs__content {
    height: 350px;
    overflow-y: auto;
}

/deep/ .step_icon {
    margin-top: 3.5px
}

.programme_item {
    border: 1px solid #CDCDCD;
    border-radius: 8px;
    padding: 10px;
    position: relative;
    cursor: pointer;
}

.programme_item_title {
    font-size: 14px;
    font-weight: bold;
}

.programme_item_subtitle {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.40);
    margin-top: 10px;
    margin-right: 70px;
}

.programme_radio {
    position: absolute;
    right: 10px;
    top: 25px;
}

.step0 {
    margin-top: -40px;
}

.step3 {
    margin-top: 20px;
}


.form-inline {
    width: 100%;
    padding: 0;
    text-align: right;
    margin: 10px 0;
}

.form-inline .el-form-item {
    margin-bottom: 0;
}

/deep/ .el-timeline-item__tail {
    left: 7px;
}

/deep/ .el-timeline-item__timestamp {
    font-size: 16px;
    color: #000;
}

/deep/ .step2 .el-form {
    padding: 15px;
    background: #FFFFFF;
    box-shadow: 0 0 4px 0 #BCBCBC;
    border-radius: 8px;
}

.step2 {
    margin-top: 20px;
}

.step2 .el-input, .step2 .el-select, .step2 .el-textarea {
    width: 780px;
}


.avatar {
    width: 80px;
    height: 80px;
}

.avatar-uploader .el-upload, .avatar-uploader-icon {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover, .avatar-uploader-icon:hover {
    border-color: #1c204f;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
}

.main-icon {
    font-size: 14px;
    color: #000000;
    font-family: "iconfont" !important;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/deep/ .el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner {
    box-shadow: none !important;
}

/deep/ .el-radio__inner {
    border-radius: 20px;
    width: 18px;
    height: 18px;
}

/deep/ .el-radio__inner::after {
    border-radius: 20px;
    width: 20px;
    height: 20px;
    background-image: url("../../assets/icon_selected.png");
    background-size: contain;
}

</style>
