<template>
    <div class="authorization_msg_top">
        <el-card
            :body-style="{
                textAlign: 'left',
                padding: '0'
            }"
            class="card1"
            shadow="never">

            <el-descriptions>
                <el-descriptions-item :label="$i18n.t('empower.text90')">{{ basicMsg.code }}</el-descriptions-item>
                <el-descriptions-item :label="$i18n.t('empower.text15')" v-if="basicMsg.type === 1">
                    <span>
                        {{ $config.getMsgItemUtil($message.empowerObjectiveList, basicMsg.objective, 'id', 'name') }}
                    </span>
                </el-descriptions-item>
<!--                <el-descriptions-item label="自动计数">-->
<!--                    {{ basicMsg.autoAuthorizationSwitch === 1 ? '开启' : '关闭' }}-->
<!--                </el-descriptions-item>-->
                <el-descriptions-item :label="$i18n.t('empower.text14')">
                    <span>
                        {{ $config.getMsgItemUtil($message.empowerObjectList, basicMsg.type, 'id', 'name') }}
                    </span>
                </el-descriptions-item>
                <el-descriptions-item :label="$i18n.t('empower.text91')">{{ basicMsg.createTime }}</el-descriptions-item>
                <el-descriptions-item :label="$i18n.t('empower.text27')">
                    <span v-if="basicMsg.state === 2">

                        <el-popover
                            placement="top-start"
                            title=""
                            trigger="hover"
                            :content="basicMsg.comments">
                            <el-button type="text" slot="reference" style="padding: 0;color: red;text-decoration: underline;">
                                {{ $config.getMsgItemUtil($message.empowerStatusList, basicMsg.state, 'id', 'name') }}
                            </el-button>
                        </el-popover>
                    </span>

                    <span v-else>
                        {{ $config.getMsgItemUtil($message.empowerStatusList, basicMsg.state, 'id', 'name') }}
                    </span>
                </el-descriptions-item>
                <el-descriptions-item :label="$i18n.t('empower.text20')">{{ $config.getMsgItemUtil($message.activeTypeList, basicMsg.activeType, 'id', 'name') }}</el-descriptions-item>
                <!--                <el-descriptions-item label="证书有效期">{{ basicMsg.validityPeriod }}天</el-descriptions-item>-->
                <el-descriptions-item :label="$i18n.t('empower.text92')"  v-if="basicMsg.allowProbation === 'Y'">{{ basicMsg.probationPeriod }}{{$i18n.t('empower.text93')}}</el-descriptions-item>
<!--                <el-descriptions-item :span="3" label="设备凭证">{{ deviceVoucher }}</el-descriptions-item>-->
                <el-descriptions-item :span="3" :label="(basicMsg.type === 1 ? $i18n.t('empower.text59') : $i18n.t('empower.text1'))">{{
                        objectName
                    }}
                </el-descriptions-item>
            </el-descriptions>

        </el-card>
        <el-card
            :body-style="{
                padding: '0'
            }"
            class="card2"
            shadow="never">
            <div class="card_title">{{$i18n.t('empower.text94')}}</div>
            <div class="number" style="color: #00B600;" ref="totalAuthorization">{{
                    $config.farmat(parseInt(basicMsg.totalAuthorization))
                }}
            </div>
        </el-card>
        <el-card
            :body-style="{
                padding: '0'
            }"
            class="card2"
            shadow="never" >
            <div class="card_title">{{$i18n.t('empower.text95')}}</div>
            <div class="number" style="color: #FE7A23;" ref="totalActivation">{{ $config.farmat(parseInt(basicMsg.totalActivation)) }}</div>
        </el-card>
        <el-card
            :body-style="{
                padding: '0'
            }"
            class="card2"
            shadow="never">
            <div class="card_title">{{$i18n.t('empower.text96')}}</div>
            <div class="number" style="color: #00B600;" ref="totalProbation">{{ $config.farmat(parseInt(basicMsg.totalAuthorization) - parseInt(basicMsg.totalActivation)) }}</div>
        </el-card>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {

    name: "authorizationMsg",

    data() {

        return {

            basicMsg: {},

            deviceVoucher: "",

            objectName: "",

            statusList: [
                {id: 1, name: this.$i18n.t('empower.text97')},
                {id: 2, name: this.$i18n.t('empower.text98')},
                {id: 3, name: this.$i18n.t('empower.text99')}
            ],

            time: ""

        }

    },


    mounted() {
        this.time = new Date().getTime();
        this.getMsg()
    },

    methods: {

        ...mapActions('empower', ['queryAuthDetails']),

        /**
         * 获取详情数据
         */
        getMsg() {
            this.queryAuthDetails({
                authorizationCode: this.$route.query.code
            }).then(res => {
                this.basicMsg = res.result;
                this.$store.commit("empower/SET_ITEM_MSG", res.result);
                let deviceVoucher = "";
                let objectName = "";
                let list = JSON.parse(res.result.objectInfo);
                list.forEach((item, index) => {
                    deviceVoucher = (index === 0 ? '' : deviceVoucher + '_') + item.objectId;
                    objectName = (index === 0 ? '' : objectName + '、') + item.objectName + '_' + item.objectId;
                })
                this.deviceVoucher = deviceVoucher;
                this.objectName = objectName;
                this.$nextTick(() => {
                    let cardHeight = document.getElementsByClassName('card1')[0].clientHeight - 40;
                    document.getElementsByClassName('card2')[0].style.height = cardHeight + 'px';
                    document.getElementsByClassName('card2')[1].style.height = cardHeight + 'px';
                    document.getElementsByClassName('card2')[2].style.height = cardHeight + 'px';
                    document.getElementsByClassName('number')[0].style.lineHeight = (cardHeight - 30) + 'px';
                    document.getElementsByClassName('number')[1].style.lineHeight = (cardHeight - 30) + 'px';
                    document.getElementsByClassName('number')[2].style.lineHeight = (cardHeight - 30) + 'px';

                    let le = this.$refs.totalAuthorization;
                    let le1 = this.$refs.totalProbation;
                    let le2 = this.$refs.totalActivation;
                    if ((le.scrollWidth > le.offsetWidth) || (le1.scrollWidth > le1.offsetWidth) || (le2.scrollWidth > le2.offsetWidth)) {
                        document.getElementsByClassName('number')[0].style.fontSize = '16px';
                        document.getElementsByClassName('number')[1].style.fontSize = '16px';
                        document.getElementsByClassName('number')[2].style.fontSize = '16px';
                    }
                })
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        }

    }

}
</script>

<style scoped>
.el-card {
    animation: fadeInRight; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s; /* don't forget to set a duration! */
}
.authorization_msg_top {
    width: 100%;
    height: 130px;
}

.card1 {
    width: calc(61% - 40px);
    min-height: 120px;
    max-height: 150px;
    float: left;
    padding: 20px;
    margin-bottom: 24px;
}

.card2 {
    width: calc(13% - 63px);
    min-height: 120px;
    max-height: 150px;
    margin-left: 20px;
    float: left;
    padding: 20px;
}

.card_title {
    text-align: left;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.90);
}

.number {
    line-height: 120px;
    font-family: PingFangSC-Medium;
    font-weight: bold;
    font-size: 30px;
}


</style>
