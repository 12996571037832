import { render, staticRenderFns } from "./adminHeader.vue?vue&type=template&id=a8b20ac8&scoped=true"
import script from "./adminHeader.vue?vue&type=script&lang=js"
export * from "./adminHeader.vue?vue&type=script&lang=js"
import style0 from "./adminHeader.vue?vue&type=style&index=0&id=a8b20ac8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8b20ac8",
  null
  
)

export default component.exports