<template>
    <div>
        <div class="page_title">
            {{$i18n.t('userInfo.text1')}}
        </div>
        <el-card
            :body-style="{
                textAlign: 'left',
                padding: '20px',
                maxWidth: '1760px',
                maxHeight: '720px',
                margin: '0 auto',
                height: height + 'px'
            }"
            shadow="never">

            <el-row style="height: 100%;background: #F7F7F7;padding: 20px">

                <el-col :span="10" style="height: 100%;">
                    <el-upload
                        class="avatar-uploader"
                        :action="$message.uploadImgUrl"
                        :data="{
                           bucket: 'enterprise'
                        }"
                        :headers="{
                            accessToken: $token.getToken().accessToken,
                            refreshToken: $token.getToken().refreshToken,
                            language: $message.language
                        }"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :on-error="onErr"
                        drag
                        :loading="loadAvatar"
                        accept=".png,.jpeg,.jpg,.gif,.bmp"
                        :before-upload="beforeAvatarUpload">

                        <el-avatar :size="106" v-if="userMsg.headImg" >
                            <img :src="userMsg.headImg"  @mouseenter="isShowUpload = true;"
                                 @mouseleave="isShowUpload = false;"/>
                            <div class="avatar-mask" v-show="isShowUpload" @mouseenter="isShowUpload = true;"
                                 @mouseleave="isShowUpload = false;">
                                <i class="el-icon-upload2 icon-upload"></i>
                            </div>
                        </el-avatar>
                        <el-avatar :size="106" style="background: transparent;" v-else>
                            <img src="../../../../assets/icon_avatar.png" @mouseenter="isShowUpload = true;"
                                 @mouseleave="isShowUpload = false;" />
                            <div class="avatar-mask" v-show="isShowUpload" @mouseenter="isShowUpload = true;"
                                 @mouseleave="isShowUpload = false;">
                                <i class="el-icon-upload2 icon-upload"></i>
                            </div>
                        </el-avatar>

                        <el-button size="small" icon="el-icon-upload2">{{$i18n.t('userInfo.text2')}}</el-button>

                        <div class="notes">{{$i18n.t('userInfo.text3')}}</div>

                    </el-upload>
                </el-col>
                <el-col :span="14" style="height: 100%;display: flex;flex-direction: column;">

                    <div class="user_item">
                        <img src="../../../../assets/icon_nickname.png" class="icon-item">
                        <div class="user_item_title">{{$i18n.t('userInfo.text4')}}</div>
                        <div class="user_item_value" v-if="isEditNickname">
                            <el-input
                                size="mini"
                                maxlength="10"
                                :placeholder="$i18n.t('userInfo.text5')"
                                v-model="userMsg.nickname">
                            </el-input>
                        </div>
                        <div class="user_item_value" v-else>{{userMsg.nickname}}</div>

                        <el-button type="text" class="user_item_btn" @click="isEditNickname = true" v-if="!isEditNickname">{{$i18n.t('userInfo.text6')}}</el-button>
                        <div class="user_item_btn" v-else>
                            <el-button type="text" style="padding: 0;margin: 4px 0 0 10px ;" :loading="loadEditNickname" @click="updateMsg('nickname')">{{$i18n.t('userInfo.text7')}}</el-button>
                            <el-button type="text" style="padding: 0;margin: 4px 0 0 10px;" @click="isEditNickname = false;userMsg.nickname = userMsgBackups.nickname;">{{$i18n.t('userInfo.text8')}}</el-button>
                        </div>
                    </div>

                    <div class="user_item">
                        <img src="../../../../assets/icon_user_id.png" class="icon-item">
                        <div class="user_item_title">{{$i18n.t('userInfo.text9')}}</div>
                        <div class="user_item_value">{{userMsg.userId}}</div>
                        <el-button type="text" class="user_item_btn" style="height: 39.6px;"></el-button>
                    </div>

                    <div class="user_item">
                        <div class="user_item_title">{{$i18n.t('userInfo.text10')}}</div>
                        <div class="user_item_value">{{userMsg.areaName}}</div>
                        <el-button type="text" class="user_item_btn" style="height: 39.6px;"></el-button>
                    </div>

                    <div class="user_item">
                        <img src="../../../../assets/icon_address.png" class="icon-item">
                        <div class="user_item_title">{{$i18n.t('userInfo.text11')}}</div>
                        <div class="user_item_value" v-if="isEditAddress">
                            <el-input
                                size="mini"
                                maxlength="50"
                                :placeholder="$i18n.t('userInfo.text12')"
                                v-model="userMsg.address">
                            </el-input>
                        </div>
                        <div class="user_item_value" v-else>{{userMsg.address}}</div>
                        <el-button type="text" class="user_item_btn" @click="isEditAddress = true" v-if="!isEditAddress">{{$i18n.t('userInfo.text13')}}</el-button>
                        <div class="user_item_btn" v-else>
                            <el-button type="text" style="padding: 0;margin: 4px 0 0 10px;" :loading="loadEditAddress" @click="updateMsg('address')">{{$i18n.t('userInfo.text7')}}</el-button>
                            <el-button type="text" style="padding: 0;margin: 4px 0 0 10px;" @click="isEditAddress = false;userMsg.address = userMsgBackups.address;">{{$i18n.t('userInfo.text8')}}</el-button>
                        </div>
                    </div>

                    <div class="user_item">
                        <img src="../../../../assets/icon_password.png" class="icon-item">
                        <div class="user_item_title">{{$i18n.t('userInfo.text14')}}</div>
                        <div class="user_item_value">******</div>
                        <el-button type="text" class="user_item_btn" @click="updatePassword">{{$i18n.t('userInfo.text15')}}</el-button>
                    </div>



                    <div style="margin-top: 100px;"></div>
                    <div class="user_item" v-if="systemConfig.supportTelephone">
                        <img src="../../../../assets/icon_phone.png" class="icon-item">
                        <div class="user_item_title">{{$i18n.t('userInfo.text16')}}</div>
                        <div class="user_item_value">
                            <span :style="{color: !userMsg.tel ? '#FA541C' : ''}">
                                {{userMsg.tel ? (`+${userMsg.areaNumber}-${userMsg.tel}`) : $i18n.t('userInfo.text17')}}
                            </span>
                        </div>
                        <div class="user_item_btn">
                            <el-button type="text" @click="updateTel" v-if="userMsg.tel">{{$i18n.t('userInfo.text18')}}</el-button>
                            <el-button type="text" v-if="!userMsg.tel" @click="bindTel">{{$i18n.t('userInfo.text19')}}</el-button>
                        </div>
                    </div>
                    <div class="user_item" v-if="systemConfig.supportEmail">
                        <img src="../../../../assets/icon_phone.png" class="icon-item">
                        <div class="user_item_title">{{$i18n.t('userInfo.text20')}}</div>
                        <div class="user_item_value">
                            <span :style="{color: !userMsg.email ? '#FA541C' : ''}">
                                {{userMsg.email ? userMsg.email : $i18n.t('userInfo.text17')}}
                            </span>
                        </div>
                        <div class="user_item_btn">
                            <el-button type="text" @click="updateEmail" v-if="userMsg.email">{{$i18n.t('userInfo.text21')}}</el-button>
                            <el-button type="text" v-if="!userMsg.email" @click="bindEmail">{{$i18n.t('userInfo.text22')}}</el-button>
                        </div>
                    </div>
                </el-col>

            </el-row>

        </el-card>

        <update-tel-dialog :areaNumberList="areaNumberList" @getUserInfo="getUserInfo"></update-tel-dialog>
        <update-password-dialog @getUserInfo="getUserInfo"></update-password-dialog>
        <bind-email-or-tel-dialog :areaNumberList="areaNumberList" @getUserInfo="getUserInfo"></bind-email-or-tel-dialog>
    </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
import updateTelDialog from '@/views/setting/views/userInfo/components/dialog/updateTelDialog';
import updatePasswordDialog from '@/views/setting/views/userInfo/components/dialog/updatePasswordDialog';
import BindEmailOrTelDialog from "@/views/setting/views/userInfo/components/dialog/bindEmailOrTelDialog";


export default {

    components: {
        BindEmailOrTelDialog,
        updateTelDialog,
        updatePasswordDialog
    },

    computed: {
        ...mapState('login', ['userInfo'])
    },

    watch: {
        userInfo: {
            handler(newVal) {
                this.userMsg = {
                    nickname: newVal.nickname,
                    userId: newVal.userId,
                    headImg: newVal.headImg,
                    address: newVal.address,
                    tel: newVal.telephone,
                    areaNumber: newVal.areaNumber,
                    email: newVal.email
                };
                this.areaNumberList.forEach(item => {
                    if (item.value === newVal.areaNumber) {
                        this.userMsg.areaName = item.label;
                    }
                })
                this.userMsgBackups = this.$config.deepCopy(this.userMsg);
            }
        }
    },


    data() {
        return {
            userMsg: {
                nickname: "",
                userId: "",
                headImg: "",
                address: ""
            },
            userMsgBackups: {
                nickname: "",
                userId: "",
                headImg: "",
                address: ""
            },
            areaNumberList: [],
            height: 300,
            loadAvatar: false,
            isEditNickname: false,
            loadEditNickname: false,
            isEditAddress: false,
            loadEditAddress: false,
            isShowUpload: false,
            systemConfig: {
                "supportTelephone": true,
                "supportWechat": true,
                "supportMicroApp": true,
                "supportApple": true,
                "supportEmail": true,
                "supportGzh": true
            }
        }
    },

    mounted() {
        setTimeout(() => {
            this.height = window.innerHeight - 190;
        }, 100)
        window.addEventListener('resize', this.setHeight);
        this.getCountryListInDataCenter();
    },

    destroyed() {
        window.removeEventListener('resize', this.setHeight);
    },

    methods: {

        ...mapActions('login', ['getAdminuserInfo', 'modifyUserInfo', 'queryCountryListInDataCenter']),

        getCountryListInDataCenter() {
            this.queryCountryListInDataCenter().then(res => {
                let list = [];
                res.result.forEach(item => {
                    list.push({
                        value: item.areaNumber,
                        label: item.localeName
                    });
                    if (item.areaNumber === this.userMsg.areaNumber) {
                        this.systemConfig = item.dataCenter.systemConfig
                    }
                })
                this.areaNumberList = list;
                this.getUserInfo();
            })
        },

        setHeight() {
            this.height = window.innerHeight - 190;
        },

        getUserInfo() {
            this.getAdminuserInfo();
        },

        updatePassword() {
            this.$store.commit("setting/SET_UPDATE_PASSWORD_DIALOG_VISIBLE", true);
        },

        updateTel() {
            this.$store.commit("setting/SET_UPDATE_TEL_DIALOG_TYPE", 0);
            this.$store.commit("setting/SET_UPDATE_TEL_DIALOG_VISIBLE", true);
        },

        updateEmail() {
            this.$store.commit("setting/SET_UPDATE_TEL_DIALOG_TYPE", 1);
            this.$store.commit("setting/SET_UPDATE_TEL_DIALOG_VISIBLE", true);
        },

        bindTel() {
            this.$store.commit("setting/SET_BIND_EMAIL_OR_TEL_TYPE", 0);
            this.$store.commit("setting/SET_BIND_EMAIL_OR_TEL_DIALOG_VISIBLE", true);
        },

        bindEmail() {
            this.$store.commit("setting/SET_BIND_EMAIL_OR_TEL_TYPE", 1);
            this.$store.commit("setting/SET_BIND_EMAIL_OR_TEL_DIALOG_VISIBLE", true);
        },

        handleAvatarSuccess(res, file) {
            console.log(res, file)
            if (res.success) {
                this.userMsg.headImg = res.result.fileList[0].url;
                this.updateMsg("avatar");
            } else {
                this.$dialog.showMessage(res.resultMsg, this.$config.TOAST_ERROR);
                console.log(res, file)
            }
        },

        updateMsg(type) {
            if (type === 'nickname' && !this.userMsg.nickname){
                this.$dialog.showMessage(this.$i18n.t('userInfo.text23'), this.$config.TOAST_WARNING);
                return;
            }
            if (type === 'address' && !this.userMsg.address){
                this.$dialog.showMessage(this.$i18n.t('userInfo.text24'), this.$config.TOAST_WARNING);
                return;
            }
            if (type === 'avatar' && !this.userMsg.headImg){
                this.$dialog.showMessage(this.$i18n.t('userInfo.text25'), this.$config.TOAST_WARNING);
                return;
            }


            type === "nickname" ? this.loadEditNickname = true : type === "address" ? this.loadEditAddress = true : this.loadAvatar = true;

            this.modifyUserInfo(this.userMsg).then(res => {
                this.getUserInfo();
                this.$dialog.showMessage(this.$i18n.t('userInfo.text26'), this.$config.TOAST_SUCCESS);

                this.isEditNickname = false;
                this.isEditAddress = false;
            }, err => {
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.loadAvatar = false;
                this.loadEditNickname = false;
                this.loadEditAddress = false;
            })
        },


        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpg';
            const isJPEG = file.type === 'image/jpeg';
            const isPNG = file.type === 'image/png';
            const isGIF = file.type === 'image/gif';
            const isBMP = file.type === 'image/bmp';

            const isSize = new Promise(function (resolve, reject) {
                let width = 200;
                let height = 200;
                let _URL = window.URL || window.webkitURL;
                let image = new Image();
                image.onload = function () {
                    let valid = image.width == width && image.height == height;
                    valid ? resolve() : reject();
                };
                image.src = _URL.createObjectURL(file);
                console.log(image.src)
            }).then(
                () => {
                    return file;
                },
                () => {
                    this.$dialog.showMessage(this.$i18n.t('userInfo.text27'), this.$config.TOAST_WARNING);
                    return Promise.reject();
                }
            );
            console.log(file)
            if (!(isJPG || isJPEG || isPNG || isGIF || isBMP)) {
                this.$dialog.showMessage(this.$i18n.t('userInfo.text28'), this.$config.TOAST_WARNING);
            }

            if ((isJPG || isJPEG || isPNG || isGIF || isBMP) && isSize) this.loadAvatar = true;
            return (isJPG || isJPEG || isPNG || isGIF || isBMP) && isSize;
        },

        /**
         * 图片上传失败回调
         */
        onErr(err, file, fileList) {
            console.log(err)
            this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_WARNING);
        },

    }

}
</script>

<style scoped>
.el-card {
    border: none;
    border-radius: 8px;
    margin: 60px 20px 20px 20px;
    animation: fadeInRight; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s; /* don't forget to set a duration! */
}

/deep/ .avatar-uploader {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
}

/deep/ .el-upload, /deep/ .el-upload-dragger {
    width: 100%;
    height: 100%;
    text-align: center;
    border: none;
    background: #F7F7F7;
}

/deep/ .el-upload-dragger {
    width: 100%;
    height: 100%;
    text-align: center;
    border-right: 1px dashed #d9d9d9;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
}

/deep/ .el-upload-dragger:hover {
    border-color: #d9d9d9;
}

.el-avatar {
    /*position: absolute;*/
    margin-top: 70px;
}

.el-upload-dragger .el-button {
    position: relative;
    margin-top: 30px;
}

.notes {
    font-weight: 400;
    font-size: 14px;
    color: rgba(138, 143, 141, 0.65);
    margin-top: 16px;
}

.user_item {
    width: 70%;
    margin-left: 15%;
    /*height: 18%;*/
    margin-top: 1%;
    position: relative;
    /*padding: 12px 32px;*/
    /*background: #FFFFFF;*/
    /*border-radius: 12px;*/
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.user_item:first-child {
    margin-top: 0;
}

.user_item_title {
    width: 16%;
    font-weight: 400;
    font-size: 16px;
    color: rgba(0,0,0,0.90);
    text-align: right;
}

.user_item_value {
    width: 50%;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0,0,0,0.45);
    display: flex;
    text-align: left;
    margin-left: 10%;
}

.user_item_btn {
    width: 30%;
    text-align: left;
}

.icon-item {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 50%;
    margin-top: -16px;
    margin-left: 30px;
    display: none;
}

.avatar-mask {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.60);
    position: relative;
    top: -100%;
}

.icon-upload {
    font-size: 24px;
    text-align: center;
    line-height: 106px;
}

</style>
