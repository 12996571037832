<template>
    <div>

        <div class="page_title">
            {{$i18n.t('role.text1')}}
        </div>
        <el-card
            :body-style="{
                textAlign: 'left',
                padding: '15px',
                height: '100%'
            }"
            shadow="never">
            <div class="header_btn">
                <el-button class="btn" size="small" type="primary" @click="addRole()" plain>{{$i18n.t('role.text2')}}</el-button>
            </div>
            <el-table
                :data="roleList"
                row-key="code"
                v-loading="loading"
                ref="roleTable"
                :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"
                :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"
                :height="tableHeight"
                default-expand-all
                :tree-props="{children: 'children'}">
                <el-table-column
                    prop="name"
                    :label="$i18n.t('role.text3')">
                </el-table-column>
                <el-table-column
                    width="200"
                    prop="remark"
                    :label="$i18n.t('role.text4')">
                </el-table-column>
                <el-table-column width="400" :label="$i18n.t('role.text5')">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="lookUp(scope.row)">{{$i18n.t('role.text6')}}</el-button>
                        <el-button type="text" size="small" @click="edit(scope.row)">{{$i18n.t('role.text7')}}</el-button>
                        <el-button type="text" size="small" @click="authorize(scope.row)">{{$i18n.t('role.text8')}}</el-button>
                        <el-button type="text" size="small" style="color:#d45c34;" @click="delRole(scope.row)">{{$i18n.t('role.text9')}}
                        </el-button>
                        <el-button type="text" size="small" :disabled="scope.row.level >= 11"
                                   @click="addSubRole(scope.row)">{{$i18n.t('role.text10')}}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <empower></empower>
            <add-role @getMsg="getMsg"></add-role>
        </el-card>
    </div>
</template>

<script>
import empower from '@/views/setting/views/roleManage/components/empower';
import addRole from '@/views/setting/views/roleManage/components/addRole';
import {mapState, mapActions} from "vuex";

export default {
    components: {
        empower,
        addRole
    },
    computed: {
        ...mapState('roleManage', ['roleMsg'])
    },
    watch: {
        roleMsg: {
            handler(newVal) {
                this.loading = false;
                this.roleList = newVal;
                console.log(newVal);
            },
            deep: true
        }
    },
    data() {
        return {
            loading: false,
            roleForm: {
                roleName: ""
            },
            tableHeight: 50,
            roleList: []
        }
    },
    mounted() {
        setTimeout(() => {
            this.tableHeight = window.innerHeight - 220;
        }, 100)
        window.addEventListener('resize', this.setHeight);
        this.getMsg();
        console.log(this)
    },
    destroyed() {
        window.removeEventListener('resize', this.setHeight);
    },
    methods: {
        ...mapActions('roleManage', ['roleTree', 'deleteRole']),

        setHeight() {
            this.tableHeight = window.innerHeight - 220;
        },
        getMsg() {
            this.loading = true;
            this.roleTree()
        },
        lookUp(row) {
            console.log(row);
            this.$store.commit("roleManage/SET_ADD_ROLE_TYPE", 4);
            this.$store.commit("roleManage/SET_ARR_ROLE_DIALOG_VISIBLE", true);
            this.$store.commit("roleManage/SET_ROLE_MSG_ITEM", row);
        },
        edit(row) {
            console.log(row);
            this.$store.commit("roleManage/SET_ADD_ROLE_TYPE", 3);
            this.$store.commit("roleManage/SET_ARR_ROLE_DIALOG_VISIBLE", true);
            this.$store.commit("roleManage/SET_ROLE_MSG_ITEM", row);
        },
        authorize(row) {
            console.log(row);
            this.$store.commit("roleManage/SET_EMPOWER_DRAWER_VISIBLE", true);
            this.$store.commit("roleManage/SET_ROLE_MSG_ITEM", row);
        },
        delRole(row) {
            console.log(row);
            this.$dialog.showConfirm({
                content: this.$i18n.t('role.text11'),
                title: this.$i18n.t('role.text9'),
                btn1: this.$i18n.t('role.text12'),
                btn2: this.$i18n.t('role.text13'),
                icon: 2
            }, () => {
                this.deleteRole({
                    code: row.code
                }).then(res => {
                    console.log(res);
                    this.$dialog.showMessage(this.$i18n.t('role.text14'), this.$config.TOAST_SUCCESS);
                    this.getMsg();
                }, err => {
                    console.log(err);
                    this.$dialog.showMessage(err.resultMsg ? err.resultMsg : this.$i18n.t('role.text15'), this.$config.TOAST_ERROR);
                })
            }, () => {
            })
        },
        addSubRole(row) {
            console.log(row);
            this.$store.commit("roleManage/SET_ADD_ROLE_TYPE", 2);
            this.$store.commit("roleManage/SET_ARR_ROLE_DIALOG_VISIBLE", true);
            this.$store.commit("roleManage/SET_ROLE_MSG_ITEM", {
                parentRoleName: row.name,
                parentCode: row.code
            });
        },
        handleCurrentChange(page) {
            console.log(page)
        },
        addRole() {
            this.$store.commit("roleManage/SET_ADD_ROLE_TYPE", 1);
            this.$store.commit("roleManage/SET_ARR_ROLE_DIALOG_VISIBLE", true);
        }
    },
}
</script>

<style scoped>

.el-card {
    border: none;
    border-radius: 8px;
    margin: 60px 20px 20px 20px;
    animation: fadeInRight; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s; /* don't forget to set a duration! */
}

.member_table {
    padding-top: 10px;
    border-radius: 4px 4px 0 0;
}

.el-input {
    width: 200px;
}

/deep/ .el-breadcrumb__inner, /deep/ .el-breadcrumb__separator {
    font-weight: bold !important;
    font-size: 16px !important;
    color: black !important;
}

.header_btn {
    width: 100%;
    margin-bottom: 10px;
}

.btn {
    float: right;
    margin-bottom: 10px;
}

/deep/ .el-table__header-wrapper {
    border-radius: 8px 8px 0 0;
}

/deep/ .cell {
    padding: 0 !important;
}
</style>
