<template>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="白名单用户"
        :visible.sync="visible"
        width="40%"
        @close="closeDialog"
        @open="openDialog"
        center>

        <el-button size="small" type="primary" plain @click="$store.commit('maintain/SET_ADD_USER_DIALOG_VISIBLE', true)">
            新增用户
        </el-button>


        <el-table
            ref="userTable"
            class="user_table"
            :data="userListMsg.list"
            size="small"
            :height="200"
            :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"
            :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"
            v-loading="loading"
            style="width: 100%;">

            <el-table-column
                label="用户ID">
            </el-table-column>

            <el-table-column
                label="手机号">
            </el-table-column>

            <el-table-column
                label="操作">
                <template slot-scope="scope">
                    <el-button type="text" style="color: #FF4D4F;" size="small">删除</el-button>
                </template>
            </el-table-column>

        </el-table>

        <el-pagination
            background
            @current-change="handleCurrentChange"
            :page-size="10"
            small
            :current-page="page"
            layout=" prev, pager, next"
            style="margin-top: 10px;text-align: right;"
            :total="parseInt(userListMsg.totalRecords)">
        </el-pagination>

        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" :loading="loading">确 认</el-button>
        </span>

        <!-- 添加用户白名单 -->
        <add-user-dialog></add-user-dialog>

    </el-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";
import AddUserDialog from "@/views/maintain/components/dialog/addUserDialog";

export default {

    name: "addWhiteListDialog",
    components: {AddUserDialog},
    computed: {
        ...mapState('maintain', ['addWhiteListDialogVisible'])
    },

    watch: {

        addWhiteListDialogVisible: {
            handler(newVal) {
                this.visible = newVal;
            }
        }

    },

    data() {

        return {

            visible: false,

            loading: false,

            userListMsg: {
                list: [],
                totalRecords: 10
            },

            page: 1

        }

    },

    methods: {

        ...mapActions('maintain', ['closeAddWhiteListDialog']),

        openDialog() {},

        closeDialog() {
            this.closeAddWhiteListDialog();
        },

        handleCurrentChange(page) {}

    }

}
</script>

<style scoped>
.user_table {
    margin-top: 10px;
}
</style>
