<template>
    <div>
        <!-- 标题 start -->
        <div class="page_title">
            <i class="el-icon-arrow-left" style="cursor: pointer;" @click="back()"></i>
            OTA列表
        </div>
        <!-- 标题 end -->


        <el-card
            :body-style="{
                textAlign: 'left',
                padding: '15px',
                height: '100%'
            }"
            shadow="never">

            <div class="query-div">
                <el-form :inline="true" size="small" :model="queryForm">
                    <el-form-item>
                        <el-input placeholder="请通过Modelname/版本号进行搜索" v-model="queryForm.id" class="input-with-select">
                            <el-select v-model="queryForm.selectType" slot="prepend" placeholder="请选择" class="select">
                                <el-option label="Modelname" value="1"></el-option>
                                <el-option label="版本号" value="2"></el-option>
                            </el-select>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-date-picker
                            class="query-form-times"
                            v-model="queryForm.times"
                            format="yyyy-MM-dd"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-select :popper-append-to-body="false" v-model="queryForm.releaseMode" placeholder="发布方式" style="width: 100px;">
                            <el-option
                                v-for="item in $message.releaseModeList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-select :popper-append-to-body="false" v-model="queryForm.upgradeType" placeholder="升级类型" style="width: 100px;">
                            <el-option
                                v-for="item in $message.upgradeTypeList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>

                <el-form :inline="true" size="small">
                    <el-form-item style="margin-right: 0;">
                        <el-button type="primary" size="small" icon="el-icon-plus" plain>新增OTA
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>


            <el-table
                ref="otaTable"
                class="ota_table"
                :data="otaMsg.list"
                :height="cardHeight - 75 - 16"
                :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"
                :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"
                v-loading="loading"
                style="width: 100%;">

                <el-table-column
                    label="ModelName">
                </el-table-column>

                <el-table-column
                    label="产品名称">
                </el-table-column>

                <el-table-column
                    label="版本号">
                </el-table-column>

                <el-table-column
                    label="升级类型">
                </el-table-column>

                <el-table-column
                    label="创建时间">
                </el-table-column>

                <el-table-column
                    label="状态">
                </el-table-column>

                <el-table-column
                    min-width="150"
                    label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small">
                            发布
                        </el-button>
                        <el-button type="text" size="small">
                            全量发布
                        </el-button>
                        <el-button type="text" size="small">
                            详情
                        </el-button>
                        <el-button type="text" size="small">
                            下架
                        </el-button>
                    </template>
                </el-table-column>

            </el-table>

            <el-pagination
                background
                @current-change="handleCurrentChange"
                :page-size="10"
                :current-page="page"
                layout=" prev, pager, next"
                style="margin-top: 10px;text-align: right;"
                :total="parseInt(otaMsg.totalRecords)">
            </el-pagination>
        </el-card>

    </div>
</template>

<script>
export default {

    name: "otaList",


    data() {
        return {
            queryForm: {
                selectType: '1',
                id: "",
                times: [],
                releaseMode: -1,
                upgradeType: -1
            },

            page: 1,

            //卡片高度
            cardHeight: 300,

            loading: false,

            otaMsg: {
                list: [],
                totalRecords: 10
            },
        }
    },

    mounted() {
        setTimeout(() => {
            this.setHeight();
        }, 100);
        window.addEventListener('resize', this.setHeight);
    },

    methods: {

        /**
         * 设备表格高度
         */
        setHeight() {
            this.cardHeight = window.innerHeight - 64 - 36 - 80;
        },

        /**
         * 返回上一页
         */
        back() {
            this.$router.back();
        },

        handleCurrentChange(page) {},

    },

    destroyed() {
        window.removeEventListener('resize', this.setHeight);
    }

}
</script>

<style scoped>

.el-card {
    border: none;
    border-radius: 8px;
    margin: 60px 20px 20px 20px;
}

.query-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.input-with-select {
    width: 360px;
}

.input-with-select .select{
    width: 120px;
}

.query-form-times {
    width: 260px;
}

/deep/ .el-table__header-wrapper {
    border-radius: 8px 8px 0 0;
}

</style>
