<template>

    <div>

        <div class="page_title">
            {{$i18n.t('member.text1')}}
        </div>
        <el-card
            :body-style="{
                textAlign: 'left',
                padding: '15px',
                height: '100%'
            }"
            shadow="never">
            <el-row>
                <el-col :span="12">
                    <el-input
                        v-model.trim="remark"
                        size="small"
                        clearable
                        :placeholder="$i18n.t('member.text2')"
                        @clear="getMsg(1)"
                        @keyup.enter.native="getMsg(1)"
                        @blur="getMsg(1)"/>
                </el-col>
                <el-col :span="12">
                    <div class="btn">
                        <el-button type="primary" size="small" icon="el-icon-plus" @click="addUser" plain>{{$i18n.t('member.text3')}}
                        </el-button>
                    </div>
                </el-col>
            </el-row>

            <el-table
                ref="memberTable"
                class="member_table"
                :data="memberMsg.list"
                :height="tableHeight"
                :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"
                :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"
                v-loading="loading"
                style="width: 100%;">
                <el-table-column
                    prop="userId"
                    :label="$i18n.t('member.text4')">
                </el-table-column>
                <el-table-column
                    prop="remark"
                    :label="$i18n.t('member.text5')">
                </el-table-column>
                <el-table-column
                    :label="$i18n.t('member.text6')">
                    <template slot-scope="scope">
                        {{ scope.row.roleName }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="productName"
                    :label="$i18n.t('member.text7')">
                    <template slot-scope="scope">
                    <span v-if="scope.row.allProduct == 'Y'">
                        {{$i18n.t('member.text8')}}
                    </span>
                        <span v-else>
                        {{ scope.row.productList }}
                    </span>
                    </template>
                </el-table-column>
                <el-table-column width="150" :label="$i18n.t('member.text8')">
                    <template slot-scope="scope">
                        <div v-if="scope.row.isOwner == 'N'">
                            <el-button type="text" size="small" @click="editMember(scope.row)">{{$i18n.t('member.text10')}}</el-button>
                            <el-button type="text" size="small" style="color:#d45c34;" @click="delMember(scope.row)">{{$i18n.t('member.text11')}}
                            </el-button>
                            <el-button type="text" size="small" v-show="isAdmin" @click="transfer(scope.row)">{{$i18n.t('member.text12')}}
                            </el-button>
                        </div>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                background
                :current-page="page"
                @current-change="handleCurrentChange"
                :page-size="10"
                layout=" prev, pager, next"
                style="float: right;margin-top: 10px;margin-bottom: 10px;"
                :total="memberMsg.totalRecords">
            </el-pagination>
            <user-dialog @getMsg="getMsg"></user-dialog>
            <transfer-enterprise-dialog></transfer-enterprise-dialog>
        </el-card>
    </div>
</template>

<script>

import {mapState, mapActions} from "vuex";
import userDialog from '@/views/setting/views/member/components/dialog/userDialog'
import transferEnterpriseDialog from '@/views/setting/views/member/components/dialog/transferEnterpriseDialog';

export default {

    computed: {
        ...mapState('login', ['userMsg'])
    },

    watch: {
        userMsg: {
            handler(newVal) {
                this.isAdmin = newVal.roleCode === "2";
            }
        },
    },

    components: {
        userDialog,
        transferEnterpriseDialog
    },

    data() {
        return {
            remark: "",
            btnLoading: false,
            memberMsg: {
                list: [],
                totalRecords: 0
            },
            isAdmin: false,
            loading: false,
            tableHeight: 50,
            page: 1
        }
    },

    mounted() {
        setTimeout(() => {
            this.tableHeight = window.innerHeight - 245;
        }, 100)
        window.addEventListener('resize', this.setHeight);
        this.getMsg();
        this.isAdmin = this.userMsg.roleCode === "2";
    },
    destroyed() {
        window.removeEventListener('resize', this.setHeight);
    },
    methods: {

        ...mapActions('member', ['queryMember', 'deleteMember']),
        ...mapActions('enterprise', ['transferEnterprise']),
        ...mapActions('login', ['loginout']),

        setHeight() {
            this.tableHeight = window.innerHeight - 245;
        },

        getMsg(page) {
            if (page) this.page = page;
            this.loading = true;
            this.queryMember({
                currPage: this.page,
                pageSize: 10,
                keyword: this.remark
            }).then(res => {
                if (!res.result.list) return;
                res.result.list.forEach(row => {
                    if (row.memberProductList) {
                        let list = [];
                        row.memberProductList.forEach(item => {
                            list.push(item.productName)
                        })
                        row.productList = list.join("、");
                    }
                });
                this.memberMsg.list = res.result.list;
                this.memberMsg.totalRecords = parseInt(res.result.totalRecords);
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.loading = false;
                this.btnLoading = false;
            })
        },

        handleCurrentChange(page) {
            this.page = page;
            this.getMsg();
        },

        delMember(row) {
            this.$dialog.showConfirm({
                content: this.$i18n.t('member.text13'),
                title: this.$i18n.t('member.text14'),
                btn1: this.$i18n.t('member.text15'),
                btn2: this.$i18n.t('member.text16'),
                icon: 2
            }, () => {
                this.deleteMember({
                    code: row.code
                }).then(res => {
                    this.$dialog.showMessage(this.$i18n.t('member.text17'), this.$config.TOAST_SUCCESS);
                    this.getMsg();
                }, err => {
                    console.log(err);
                    this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                })
            })
        },

        transfer(row) {
            this.$dialog.showConfirm({
                content: this.$i18n.t('member.text18'),
                title: this.$i18n.t('member.text14'),
                btn1: this.$i18n.t('member.text15'),
                btn2: this.$i18n.t('member.text16'),
                icon: 2
            }, () => {
                this.transferEnterprise({
                    userId: row.userId
                }).then(res => {
                    this.$dialog.showMessage(this.$i18n.t('member.text19'), this.$config.TOAST_SUCCESS);
                    this.loginout();
                }, err => {
                    console.log(err);
                    this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                })
            })
        },

        editMember(row) {
            this.$store.commit("member/SET_USER_DIALOG_VISIBLE", true);
            this.$store.commit("member/SET_USER_DIALOG_TYPE", 1);
            this.$store.commit("member/SET_USER_ITEM_MSG", row);
        },

        addUser() {
            this.$store.commit("member/SET_USER_DIALOG_VISIBLE", true);
            this.$store.commit("member/SET_USER_DIALOG_TYPE", 0);
        },

        // transfer() {
        //     this.$store.commit("member/SET_TRANSFER_ENTERPRISE_DIALOG_VISIBLE", true);
        // }

    }

}

</script>

<style scoped>
.btn {
    float: right;
}

.el-card {
    border: none;
    border-radius: 8px;
    margin: 60px 20px 20px 20px;
    animation: fadeInRight; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s; /* don't forget to set a duration! */
}

.member_table {
    padding-top: 10px;
    border-radius: 4px 4px 0 0;
}

.el-input {
    width: 200px;
}

/deep/ .el-breadcrumb__inner, /deep/ .el-breadcrumb__separator {
    font-weight: bold !important;
    font-size: 16px !important;
    color: black !important;
}

.search_btn {
    margin-left: 10px;
}

/deep/ .el-table__header-wrapper {
    border-radius: 8px 8px 0 0;
}

/deep/ .cell {
    padding: 0 !important;
}
</style>
