<template>
    <div>
        <div class="page_title">
            {{$i18n.t('enterpriseInfo.text1')}}
        </div>
        <el-card
            :body-style="{
                textAlign: 'left',
                padding: '15px',
                height: '100%'
            }"
            shadow="never">
            <div class="header_btn">
                <el-button class="btn" size="small" type="primary" @click="toAuthentication" plain>{{$i18n.t('enterpriseInfo.text2')}}</el-button>
            </div>
            <el-table
                ref="enterpriseTable"
                class="enterprise_table"
                :data="enterpriseMsg.list"
                :height="tableHeight"
                :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)',fontSize:'14px', paddingLeft: '24px'}"
                :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '16px 24px'}"
                v-loading="loading"
                style="width: 100%;">
                <el-table-column
                    prop="fullName"
                    :label="$i18n.t('enterpriseInfo.text3')">
                </el-table-column>
                <el-table-column
                    prop="shortName"
                    :label="$i18n.t('enterpriseInfo.text4')">
                </el-table-column>
                <el-table-column
                    prop="shortNameEn"
                    :label="$i18n.t('enterpriseInfo.text5')">
                </el-table-column>
                <el-table-column
                    prop="legalPerson"
                    :label="$i18n.t('enterpriseInfo.text6')">
                </el-table-column>
                <el-table-column
                    prop="contacts"
                    :label="$i18n.t('enterpriseInfo.text7')">
                </el-table-column>
                <el-table-column
                    prop="contactNumber"
                    :label="$i18n.t('enterpriseInfo.text8')">
                </el-table-column>

                <el-table-column
                    width="200"
                    :label="$i18n.t('enterpriseInfo.text9')">
                    <template slot-scope="scope">
                        <span
                            :style="{
                                color: setColor(scope.row.state),
                                border: '1px solid ' + setColor(scope.row.state),
                                borderRadius: '4px',
                                width: 'auto',
                                fontSize: '12px',
                                padding: '3px'
                            }">
                            {{
                                $config.getMsgItemUtil($message.certifiedEnterpriseStatusList, scope.row.state, 'id', 'name')
                            }}
                        </span>
                    </template>
                </el-table-column>

                <el-table-column :label="$i18n.t('enterpriseInfo.text10')" align="left">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="modifyCertification(scope.row)"
                                   v-if="scope.row.state === 2">{{$i18n.t('enterpriseInfo.text11')}}
                        </el-button>
                        <span style="color: #1890FF;" v-else>—</span>
                    </template>
                </el-table-column>
                <!--                <el-table-column-->
                <!--                    prop="roleName"-->
                <!--                    label="担任角色">-->
                <!--                </el-table-column>-->
                <!--                <el-table-column-->
                <!--                    prop="productName"-->
                <!--                    label="负责产品">-->
                <!--                </el-table-column>-->
            </el-table>
            <el-pagination
                background
                @current-change="handleCurrentChange"
                :page-size="10"
                :current-page="page"
                layout=" prev, pager, next"
                style="float: right;margin-top: 10px;margin-bottom: 10px;"
                :total="enterpriseMsg.totalRecords">
            </el-pagination>
        </el-card>
    </div>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {

    data() {

        return {

            enterpriseMsg: {
                list: [],
                totalRecords: 0
            },

            loading: false,

            tableHeight: 50,

            roleList: [
                {id: 1, name: this.$i18n.t('enterpriseInfo.text12')},
                {id: 2, name: this.$i18n.t('enterpriseInfo.text13')},
                {id: 3, name: this.$i18n.t('enterpriseInfo.text14')}
            ],

            page: 1

        }

    },

    mounted() {
        setTimeout(() => {
            this.tableHeight = window.innerHeight - 245;
        }, 100)
        window.addEventListener('resize', this.setHeight);
        this.getMsg();
    },

    destroyed() {
        window.removeEventListener('resize', this.setHeight);
    },

    methods: {

        ...mapActions('enterprise', ['queryEnterpriseToIotpager']),

        setHeight() {
            this.tableHeight = window.innerHeight - 245;
        },

        getMsg() {
            this.loading = true;
            this.queryEnterpriseToIotpager({
                currPage: this.page,
                pageSize: 10
            }).then(res => {
                this.loading = false;
                this.enterpriseMsg.list = res.result.list;
                this.enterpriseMsg.totalRecords = parseInt(res.result.totalRecords);
            }, err => {
                this.loading = false;
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },

        /**
         * 设置状态颜色
         * @param index
         */
        setColor(index) {
            return index === 0 ? '#1890FF' : index === 1 ? '#00B600' : '#FF2825';
        },

        modifyCertification(row) {
            this.$router.push({path: "/authentication", query: {id: row.enterpriseId}})
        },

        handleCurrentChange(page) {
            this.page = page;
            this.getMsg();
        },

        toAuthentication() {
            this.$router.push({path: "/authentication"})
        }

    },
}
</script>

<style scoped>
.enterprise_table {
    padding-top: 10px;
    border-radius: 4px 4px 0 0;
}

.el-card {
    border: none;
    border-radius: 8px;
    margin: 60px 20px 20px 20px;
    animation: fadeInRight; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s; /* don't forget to set a duration! */
}

/deep/ .el-breadcrumb__inner, /deep/ .el-breadcrumb__separator {
    font-weight: bold !important;
    font-size: 16px !important;
    color: black !important;
}

.header_btn {
    width: 100%;
}

.btn {
    float: right;
}

/deep/ .el-table__header-wrapper {
    border-radius: 8px 8px 0 0;
}

/deep/ .cell {
    padding: 0 !important;
}
</style>
